import React from "react"
import { graphql } from "gatsby"
import Metadata from '../components/Metadata'
import Layout from "../components/Layout"
import '../pages/blog.css'
import { useI18next } from 'gatsby-plugin-react-i18next';

export default ({ location, data }) => {  
  const post = data.post  
  return (
    <Layout>
      <Metadata
        type="post"
        pathname={location.pathname}
        title={post.frontmatter.title}
        description={post.excerpt}
        date={post.frontmatter.rawDate}
        update={post.frontmatter.rawUpdate}
        image={post.frontmatter.image || null}
      />
      <div className="bg-light">
        <div className="mx-auto py-md-3 px-md-5 mb-2 bg-white blog-post">
          <div class="col-md-8 col-xs-12 mx-auto py-3 px-md-5 px-xs-1 mb-2 bg-color-white blog-post">
            <div dangerouslySetInnerHTML={{ __html: post.html }} class="mb-5"/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`  
  query($slug: String!, $language: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "fr")
        update(formatString: "DD MMMM YYYY", locale: "fr")
        rawDate: date
        rawUpdate: update
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

